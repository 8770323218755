<template>
  <section class="home">
    <tzzz-image
      class="d-block h-100 w-100 position-absolute"
      style="z-index: -100"
      :image="home"
      :sizes="['100vw']"
    />
    <div class="text text-center">
      <span></span>
      <div class="text-white">
        <logo class="center logo" />
        <h1>Soyez les bienvenus</h1>
        <h1 class="subtitle">au cœur de notre espace de soins</h1>
      </div>
      <b-button :to="{ name: 'contact' }">
        <phone />Prendre un rendez-vous
      </b-button>
    </div>
  </section>
</template>

<script>
import Logo from "@/assets/logo.svg";
import Phone from "@/assets/phone-solid.svg";
import Home from "@/assets/images/home.jpg?sizes=2400w+1920w+1600w+1366w+1024w+768w+640w";

import TzzzImage from "@/components/TzzzImage.vue";

export default {
  components: {
    Logo,
    Phone,
    Home,
    TzzzImage
  },
  data() {
    return {
      home: Home
    };
  }
};
</script>

<style lang="postcss" scoped>
.home {
  height: 100%;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--blue-alpha);
}
svg.logo {
  height: 6rem;
  animation: heartbeat 1.3s infinite;
  margin-bottom: 1rem;
  & path {
    fill: var(--white);
  }
}
h1 {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 110%;
  &.subtitle {
    font-size: 1.75rem;
    font-weight: 300;
  }
}
@media (min-width: 992px) {
  svg.logo {
    height: 8rem;
  }
  h1 {
    font-size: 5rem;
    &.subtitle {
      font-size: 2.75rem;
    }
  }
}

.btn {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  border: none;
  display: inline-block;
  color: var(--yellow);
  background-color: var(--gray);
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0.75rem 1.25rem;
}
.btn svg {
  height: 2rem;
  position: absolute;
  left: -2.7rem;
  bottom: 0.35rem;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  18% {
    transform: scale(1.3);
  }
  40% {
    transform: scale(1);
  }
  55% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1);
  }
}
</style>

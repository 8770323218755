<template>
  <b-container>
    <transition appear name="fall">
      <b-navbar
        toggleable="lg"
        fixed="top"
        type="light"
        variant="white"
        class="m-3 mx-lg-5"
      >
        <b-navbar-brand :to="{ name: 'home' }" class="d-lg-none">
          <logo />
        </b-navbar-brand>
        <b-navbar-toggle
          target="nav_collapse"
          class="ml-auto"
        ></b-navbar-toggle>
        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav class="justify-content-end">
            <b-nav-item :to="{ name: 'practice' }" class="text-center"
              >NOTRE CABINET</b-nav-item
            >
            <b-nav-item :to="{ name: 'team' }" class="text-center"
              >NOTRE ÉQUIPE</b-nav-item
            >
          </b-navbar-nav>
          <b-navbar-brand
            :to="{ name: 'home' }"
            class="d-none d-lg-inline-block"
          >
            <logo />
          </b-navbar-brand>
          <b-navbar-nav>
            <b-nav-item :to="{ name: 'care-offer' }" class="text-center"
              >NOTRE OFFRE DE SOINS</b-nav-item
            >
            <b-nav-item :to="{ name: 'contact' }" class="text-center"
              >CONTACT ET ACCÈS</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </transition>
  </b-container>
</template>

<script>
import Logo from "@/assets/logo.svg";

export default {
  components: { Logo }
};
</script>

<style lang="postcss" scoped>
.navbar {
  font-size: 1.2rem;
  font-weight: 500;
}
.navbar-brand {
  margin: 0;
  padding: 0.5rem 1rem;
  & svg {
    height: 2.75rem;
  }
}
.navbar-collapse {
  justify-content: center;
}
.navbar-toggler {
  border: none;
}
ul {
  flex-basis: 50%;
}
.bg-white {
  background-color: var(--bg-white) !important;
}
.nav-item {
  padding-left: 1rem;
  padding-right: 1rem;
}

.fall-enter-active {
  transition: all 0.5s cubic-bezier(0.87, 0, 0.19, 2.4);
  transition-delay: 2s;
}

.fall-enter {
  transform: translateY(-100%);
}
</style>

import Vue from "vue";
import Router from "vue-router";
import CareOffer from "./views/CareOffer.vue";
import Contact from "./views/Contact.vue";
import Home from "./views/Home.vue";
import Practice from "./views/Practice.vue";
import Team from "./views/Team.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/notre-cabinet",
      name: "practice",
      component: Practice
    },
    {
      path: "/notre-equipe",
      name: "team",
      component: Team
    },
    {
      path: "/notre-offre-de-soins",
      name: "care-offer",
      component: CareOffer
    },
    {
      path: "/contact-et-acces",
      name: "contact",
      component: Contact
    }
  ]
});

<template>
  <div id="app">
    <audio
      src="/audio/10 Gramatik - Muy Tranquilo.mp3"
      autoplay
      loop
      type="audio/mpeg"
    ></audio>
    <the-header />
    <transition name="fade" mode="in-out">
      <router-view></router-view>
    </transition>
    <div class="fixed-bottom text-center text-light">
      <b-link
        style="font-size: .8rem"
        class="text-light"
        v-b-modal.legalAndCredits
        >Crédits & mentions légales</b-link
      >
    </div>
    <b-modal id="legalAndCredits" centered hide-footer size="lg">
      <h5>Crédits photo&nbsp;:</h5>
      <p>
        <a href="https://www.gauthoustudio.fr/" target="_blank"
          >Gauthoustudio</a
        >
      </p>
      <h5>Directeur de la publication&nbsp;:</h5>
      <p>
        Cabinet dentaire du docteur Patrice Gaujard.
        <br />750, Rue Aristide Bergès 38330 Montbonnot Saint-Martin
        <br />Tél.&nbsp;: 04 76 77 53 69 <br />Numéro d'inscription à l'ordre
        des chirurgiens dentistes&nbsp;: 52966
      </p>
      <h5>Objet du site&nbsp;:</h5>
      <p>
        Le site web a pour pour objectif de vous informer sur le cabinet, son
        fonctionnement, ses horaires d’ouvertures, son personnel ainsi que sur
        les traitements proposés.
      </p>
      <p>
        L'information mise à disposition sur le site web vise à soutenir et non
        à remplacer la relation entre un patient et son chirurgien-dentiste.
      </p>
      <h5>Hébergement du site&nbsp;:</h5>
      <p>Linode</p>
      <h5>Publicité&nbsp;:</h5>
      <p>Ce site Internet ne diffuse et n’accepte pas de publicité.</p>
      <h5>Propriété intellectuelle&nbsp;:</h5>
      <p>Ce site Internet a été conçu par Ma little agence.</p>
      <p>
        Toute reproduction, distribution, modification, retransmission ou
        publication de ces différents éléments est strictement interdite sans
        l’accord écrit de la société Ma little Agence.
      </p>
      <h5>Confidentialité&nbsp;:</h5>
      <p>
        La fourniture d’informations personnelles n’est pas indispensable pour
        la navigation sur le site. Le secret professionnel s’impose à tout
        chirurgien-dentiste, sauf dérogations prévues par la loi.
      </p>
      <p>
        Les informations personnelles concernant les visiteurs du site sont
        confidentielles. Les responsables du site s’engagent sur l’honneur à
        respecter les conditions légales de confidentialité applicables en
        France et à ne pas divulguer ces informations à des tiers.
      </p>
      <p>
        La CNIL a été créée par la loi n° 78-17 du 6 janvier 1978 relative à
        l’informatique, aux fichiers et aux libertés, modifiée en 2004.
      </p>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import TheHeader from "@/components/TheHeader.vue";

export default Vue.extend({
  components: { TheHeader },
  data() {
    return {
      title: "Clinique dentaire à Montbonnot - Dr Gaujard",
      description: `Bienvenue dans notre espace de soins dentaires pour enfants et adultes et bénéficiez des dernières technologies en implantologie, orthodontie, parodontologie, et esthétique dentaire.`
    };
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: null,
      meta: [
        {
          property: "description",
          content: this.description
        },
        {
          property: "og:image",
          content:
            process.env.VUE_APP_HOSTNAME +
            process.env.VUE_APP_BASE_URL +
            "/public/cpg.png"
        },
        {
          property: "og:locale",
          content: "fr_FR"
        },
        {
          property: "og:title",
          content: this.title
        },
        {
          property: "og:description",
          content: this.description
        },
        {
          property: "og:url",
          content:
            process.env.VUE_APP_HOSTNAME +
            process.env.VUE_APP_BASE_URL +
            this.$route.path
        }
      ]
    };
  }
});
</script>

<style lang="postcss">
:root {
  --font-family-sans-serif: "Terminal Dosis", Helvetica, Arial, sans-serif;
  --blue: #24b8c9;
  --blue-alpha: #24b8c9aa;
  --gray: #4c4c4c;
  --yellow: #ebdc36;
  --white: #ffffff;
  --bg-white: color-mod(var(--white) alpha(80%));
  --green-logo: #97c400;
  --blue-logo: #3ab9cc;
  --bg-gray: #6d6c754b;
  --bg-blue: color-mod(var(--blue) blackness(30%));
}

@font-face {
  font-family: "Terminal Dosis";
  src: url("./assets/fonts/TerminalDosis-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/TerminalDosis-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Terminal Dosis";
  src: url("./assets/fonts/TerminalDosis-Bold.woff2") format("woff2"),
    url("./assets/fonts/TerminalDosis-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Terminal Dosis";
  src: url("./assets/fonts/TerminalDosis-Book.woff2") format("woff2"),
    url("./assets/fonts/TerminalDosis-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Terminal Dosis";
  src: url("./assets/fonts/TerminalDosis-Light.woff2") format("woff2"),
    url("./assets/fonts/TerminalDosis-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Terminal Dosis";
  src: url("./assets/fonts/TerminalDosis-Medium.woff2") format("woff2"),
    url("./assets/fonts/TerminalDosis-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

body {
  font: 1rem/1.5 var(--font-family-sans-serif);
  background-color: var(--bg-blue);
}
@media (min-width: 992px) {
  body {
    overflow: hidden;
  }
}
a {
  outline: 0;
}
#app {
  width: 100vw;
  max-width: 2200px;
  height: 100vh;
  margin: auto;
}
svg {
  height: 2rem;
}

/* Slide */
.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 0.5s ease;
}

.slide-top-leave-to {
  transform: translateX(100%);
}
.slide-top-enter {
  transform: translateX(-100%);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

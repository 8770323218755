<template>
  <b-col class="bg-blue" lg="5" :order-lg="orderLg">
    <section class="text-white d-flex flex-column h-100 overflow-custom">
      <slot />
    </section>
  </b-col>
</template>

<script>
export default {
  props: ["orderLg"]
};
</script>

<style lang="postcss" scoped>
.bg-blue {
  background-color: var(--blue);
}
section {
  padding: 8rem 2rem 1rem 2rem;
  font-size: 1.4rem;
  font-weight: 400;
}
@media (min-width: 992px) {
  section {
    padding: 8rem 3rem 6rem 3rem;
  }
}
h3 {
  margin-bottom: 2rem;
}
h3:after {
  content: "";
  display: block;
  width: 5rem;
  margin-top: 12px;
  border: 3px solid var(--white);
}
.accordion-toggle {
  cursor: pointer;
}
.accordion-toggle:hover::after {
  border-color: var(--yellow);
}
</style>
<style lang="postcss">
@media (min-width: 992px) {
  .overflow-custom {
    overflow-y: auto;
    max-height: 100vh;
  }
}
</style>

<template>
  <b-container fluid class="d-flex px-0 h-100">
    <b-row no-gutters class="w-100">
      <the-accordion :order-lg="2">
        <p class="mb-4">
          Un chirurgien dentiste et deux assistantes composent notre équipe.
        </p>
        <h3 class="accordion-toggle" v-b-toggle.accordion1>PATRICE GAUJARD</h3>
        <b-collapse id="accordion1" visible accordion="my-accordion">
          <ul class="mb-5">
            <li>
              Docteur en chirurgie dentaire, diplômé de la faculté de Lyon en
              1998.
            </li>
            <li>DU d’implantologie orale.</li>
            <li>Formation d’endodontie sous microscope.</li>
            <li>Formation en parodontologie.</li>
          </ul>
        </b-collapse>
        <h3 class="accordion-toggle" v-b-toggle.accordion3>MATHILDE</h3>
        <b-collapse id="accordion3" accordion="my-accordion">
          <ul class="mb-5">
            <li>Assistante dentaire, chargée de communication.</li>
            <li>
              Diplômée depuis 1995, collabore avec le docteur GAUJARD depuis
              2000.
            </li>
            <li>
              Mathilde est à votre écoute pour répondre à vos questions, vous
              présenter les plans de traitement et trouver avec vous des
              solutions.
            </li>
          </ul>
        </b-collapse>
        <h3 class="accordion-toggle" v-b-toggle.accordion4>ESTELLE</h3>
        <b-collapse id="accordion4" accordion="my-accordion">
          <ul class="mb-5">
            <li>
              Assistante dentaire formée en stérilisation, ergonomie et
              chirurgie.
            </li>
            <li>
              Diplômée depuis 1998 et collabore avec le docteur GAUJARD depuis
              2012.
            </li>
            <li>
              Estelle vous accompagne lors de vos rendez-vous de soins et de
              chirurgie. Elle est à votre écoute et répondra à toutes les
              questions relatives à votre rendez-vous.
            </li>
          </ul>
        </b-collapse>
      </the-accordion>
      <the-carousel :images="images"></the-carousel>
    </b-row>
  </b-container>
</template>

<script>
import TheAccordion from "@/components/TheAccordion.vue";
import TheCarousel from "@/components/TheCarousel.vue";

import Team1 from "@/assets/images/team-1.jpg?sizes=1284w+1120w+934w+768w+640w";
import Team2 from "@/assets/images/team-2.jpg?sizes=1284w+1120w+934w+768w+640w";
import Team3 from "@/assets/images/team-3.jpg?sizes=1284w+1120w+934w+768w+640w";

export default {
  components: { TheAccordion, TheCarousel },
  data() {
    return {
      images: [Team1, Team2, Team3],
      title: "L'équipe du cabinet dentaire Gaujard à Montbonnot",
      description: `Un chirurgien dentiste diplômé d'état et trois assistantes dentaire vous
      accueillent pour vos soins dans une ambiance détendue, chaleureuse et professionnelle.`
    };
  }
};
</script>

<style lang="postcss" scoped>
ul {
  margin-left: -1.25rem;
}
</style>

<template>
  <div class="wrapper">
    <div v-bind:style="ratioStyle"></div>
    <div class="image-wrapper" v-bind:style="backgroundcolorStyle">
      <img
        :src="placeholderUrl"
        @load="isPlaceholderLoaded = true"
        v-bind:class="placeholderClassObject"
      />
      <img
        :src="imageSrc"
        :srcset="image.srcSet"
        :sizes="sizesStr"
        @load="isFullsizeLoaded = true"
        v-bind:class="fullsizeClassObject"
        class="fullsize"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TzzzImage",
  data: function() {
    return {
      isPlaceholderLoaded: false,
      isFullsizeLoaded: false
    };
  },
  props: {
    image: Object,
    sizes: Array[String]
  },
  computed: {
    placeholderUrl: function() {
      return this.image.placeholder.url;
    },
    backgroundcolorStyle: function() {
      return {
        backgroundColor: `rgba(${this.image.placeholder.color.join(",")})`
      };
    },
    ratioStyle: function() {
      return {
        paddingBottom: `${100 / this.image.placeholder.ratio}%`
      };
    },
    imageSrc: function() {
      return this.image.sources["1366w"];
    },
    imageSrcSet: function() {
      return this.image.srcSet;
    },
    sizesStr: function() {
      return this.sizes.join(",");
    },
    placeholderClassObject: function() {
      return {
        show: this.isPlaceholderLoaded
      };
    },
    fullsizeClassObject: function() {
      return {
        show: this.isFullsizeLoaded
      };
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: relative;
}
.image-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}
img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  box-sizing: border-box;
  transition: opacity 0.25s 0.5s;
  visibility: hidden;
  opacity: 0;
  object-fit: cover;
}
.show {
  visibility: visible;
  opacity: 1;
}
</style>

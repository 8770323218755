<template>
  <b-container fluid class="d-flex px-0 h-100">
    <b-row no-gutters class="w-100">
      <the-accordion>
        <h3 class="accordion-toggle" v-b-toggle.accordion1>LE CABINET</h3>
        <b-collapse id="accordion1" visible accordion="my-accordion">
          <div class="mb-5">
            <p>« Le plaisir de sourire »</p>
            <p>
              Bienvenue au cabinet dentaire du Docteur Patrice GAUJARD situé à
              Montbonnot Saint-Martin dans le Pôle Santé. Le cabinet vous
              propose des traitements en implantologie (pose d’implant
              dentaire), esthétique dentaire (blanchiment dentaire, facettes
              céramiques), parodontologie (maladies des gencives).
            </p>
            <p>
              Notre équipe souriante, à votre écoute est composée d’un
              chirurgien dentiste et de deux assistantes.
            </p>
          </div>
        </b-collapse>
        <h3 class="accordion-toggle" v-b-toggle.accordion2>
          HYGIÈNE ET ASEPSIE
        </h3>
        <b-collapse id="accordion2" accordion="my-accordion">
          <p class="mb-5">
            Nous sommes equipé d’une chaîne de stérilisation complète , aux
            normes et bénéficiant des dernières techniques.
          </p>
        </b-collapse>
        <h3 class="accordion-toggle" v-b-toggle.accordion3>
          HONORAIRES ET REMBOURSEMENTS
        </h3>
        <b-collapse id="accordion3" accordion="my-accordion">
          <p>
            Nous proposons des soins conventionnés. Concernant les actes
            partiellement ou non remboursés, un devis préalable vous sera
            proposé.
          </p>
        </b-collapse>
      </the-accordion>
      <the-carousel :images="images"></the-carousel>
    </b-row>
  </b-container>
</template>

<script>
import TheAccordion from "@/components/TheAccordion.vue";
import TheCarousel from "@/components/TheCarousel.vue";

import Practice1 from "@/assets/images/practice-1.jpg?sizes=1284w+1120w+934w+768w+640w";
import Practice2 from "@/assets/images/practice-2.jpg?sizes=1284w+1120w+934w+768w+640w";
import Practice3 from "@/assets/images/practice-3.jpg?sizes=1284w+1120w+934w+768w+640w";
import Practice4 from "@/assets/images/practice-4.jpg?sizes=1284w+1120w+934w+768w+640w";

export default {
  components: { TheAccordion, TheCarousel },
  data() {
    return {
      images: [Practice1, Practice2, Practice3, Practice4],
      title:
        "Dr Patrice Gaujard - chirurgien dentiste à Montbonnot Saint Martin - Pôle santé",
      description: `Le cabinet est spécialisé en orthodontie, implantologie, chirurgie, dents de sagesses, parodontologie, et esthétique dentaire (prothèse dentaire, facette céramique, blanchiment). Soins conventionnés ou sur devis.`
    };
  }
};
</script>

<template>
  <b-container fluid class="d-flex px-0 h-100">
    <b-row no-gutters class="w-100">
      <the-accordion>
        <h3 class="accordion-toggle" v-b-toggle.accordion1>IMPLANTOLOGIE</h3>
        <b-collapse id="accordion1" visible accordion="my-accordion">
          <div class="mb-5">
            <p>
              L'implantologie permet aux patients ayant perdu une ou plusieurs
              dents de les remplacer de manière fiable et durable. Le Docteur
              Patrice GAUJARD pratique l'implantologie depuis plus de 15 ans.
            </p>
            <p>
              La pose d’implant dentaire a lieu uniquement lorsque votre
              dentiste est certain que vos dents naturelles ne peuvent plus être
              conservées. Les interventions chirurgicales ont lieu dans une
              salle de chirurgie dédiée à la pose d’implant dentaire et à la
              chirurgie. Les implants posés sont de marque française. Toutes les
              prothèses sont réalisées dans un laboratoire français situé à La
              Tronche.
            </p>
          </div>
        </b-collapse>
        <h3 class="accordion-toggle" v-b-toggle.accordion2>PARODONTOLOGIE</h3>
        <b-collapse id="accordion2" accordion="my-accordion">
          <div class="mb-5">
            <p>Qu’est-ce qu’une maladie parodontale ?</p>
            <p>
              C’est une maladie d’origine infectieuse (bactéries) qui touche et
              détruit les tissus de soutien des dents (gencives et os). Cette
              pathologie est assez lente et évolue sur plusieurs dizaines
              d’années.
            </p>
            <p>Les symptômes :</p>
            <ul>
              <li>le saignement gingival lors du brossage ou lors des repas</li>
              <li>la rétraction des gencives</li>
              <li>
                la perte, le déplacement et la création d’espace entre les dents
              </li>
              <li>la perte spontanée de dents.</li>
            </ul>
            <p>
              Il est important de dépister au plutôt une maladie parodontale,
              pour en limiter l’impact au niveau de la santé et pour préserver
              son capital dentaire. Des traitements existent, nous pouvons vous
              aider à conserver vos dents.
            </p>
          </div>
        </b-collapse>
        <h3 class="accordion-toggle" v-b-toggle.accordion3>
          ESTHÉTIQUE DENTAIRE
        </h3>
        <b-collapse id="accordion3" accordion="my-accordion">
          <div class="mb-5">
            <p>
              Plusieurs traitements sont regroupés sous le nom d’esthétique
              dentaire : pose de facettes céramiques, blanchiment dentaire,
              inlays/onlays, reconstitutions complètes en résines, composites
              esthétiques…
            </p>
            <p>
              N’hésitez pas à nous consulter pour que nous abordions avec vous
              ces sujets.
            </p>
          </div>
        </b-collapse>
      </the-accordion>
      <the-carousel :images="images"></the-carousel>
    </b-row>
  </b-container>
</template>

<script>
import TheAccordion from "@/components/TheAccordion.vue";
import TheCarousel from "@/components/TheCarousel.vue";

// (2400,1920,1600,1366,1024,768,640) * 7 / 12
// 1284, 1120, 934, 768, 640

import CareOffer1 from "@/assets/images/care-offer-1.jpg?sizes=1284w+1120w+934w+768w+640w";
import CareOffer2 from "@/assets/images/care-offer-3.jpg?sizes=1284w+1120w+934w+768w+640w";
import CareOffer3 from "@/assets/images/care-offer-4.jpg?sizes=1284w+1120w+934w+768w+640w";

export default {
  components: { TheAccordion, TheCarousel },
  data() {
    return {
      images: [CareOffer1, CareOffer2, CareOffer3],
      title: "Les soins dentaires au cabinet du Dr Gaugard",
      description: `Le cabinet vous accueille pour votre suivi dentaire, soins des gencives, orthodontie chez les enfants et les adultes (gouttières), esthétiques (blanchiment dentaire, facettes) et chirurgie (implants dentaire, dents de sagesses, aromathérapie)`
    };
  }
};
</script>

<template>
  <b-col lg="7" class="bg-gray">
    <b-carousel
      id="carousel1"
      indicators
      class="h-100 carousel-fade"
      interval="5000"
    >
      <b-carousel-slide
        v-for="imgSrcSet in images"
        :key="imgSrcSet"
        class="h-100"
      >
        <tzzz-image
          slot="img"
          class="d-block h-100 w-100"
          style="object-fit: cover;"
          :image="imgSrcSet"
          :sizes="[
            '100vw',
            '(min-width: 768px) 58vw',
            '(min-width: 2200px) 1284px'
          ]"
        />
      </b-carousel-slide>
    </b-carousel>
  </b-col>
</template>

<script>
import TzzzImage from "@/components/TzzzImage.vue";
export default {
  components: { TzzzImage },
  props: ["images"]
};
</script>

<style scoped>
.bg-gray {
  background-color: var(--gray);
}
</style>

<style>
.carousel-inner {
  height: 100%;
}
/* .carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  transition-duration: 3s;
  transition-timing-function: ease-in-out;
}
.carousel-fade .carousel-item {
  transition-duration: 3s;
} */
</style>

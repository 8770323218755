
module.exports = {
  sources: 
{
  "1284w": require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=1284!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=1284w"),
"1120w": require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=1120!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=1120w"),
"934w": require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=934!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=934w"),
"768w": require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=768!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=768w"),
"640w": require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=640!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=640w")
}
,
  srcSet: require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=1284!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=1284w") + " 1284w"+","+require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=1120!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=1120w") + " 1120w"+","+require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=934!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=934w") + " 934w"+","+require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=768!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=768w") + " 768w"+","+require("-!/workspaces/gaujard-frontend/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspaces/gaujard-frontend/node_modules/image-webpack-loader/index.js!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/resize-loader?size=640!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg?size=640w") + " 640w",
  placeholder: require('!!/workspaces/gaujard-frontend/node_modules/srcset-loader/lib/placeholder-loader?lightweight=false!/workspaces/gaujard-frontend/src/assets/images/team-1.jpg'),
};
